<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-03-12 15:18:21
 * @LastEditTime: 2024-05-30 11:22:42
 * @FilePath: \awx-ui\src\pages\fileManage\fileList.vue
-->
<template>
  <div
    ref="dragArea"
    :class="['file-list-page']"
    @mousedown="startDrag($event)"
    @mousemove="dragging($event)"
    @mouseup="endDrag($event)"
    @contextmenu.prevent="(e) => parentShow(e)"
  >
    <div class="file-list-page-container">
      <div class="table-info">
        <!-- <a-table :columns="columns" :loading="loading" childrenColumnName="child" :row-selection="rowSelection" :dataSource="fileList" rowKey="fullpath" :pagination="false"></a-table> -->
        <a-virtual-table
        :columns="columns" :loading="loading" childrenColumnName="child" :row-selection="rowSelection" rowKey="fullpath" :pagination="false"
        :data-source="fileList"
        :customRow="handleClickRow"
        :itemSize="32"
        keyProp="fullpath"
        :scroll="{ y: tableY }">
          <!-- <a slot="name" slot-scope="{text}">{{ text }}</a> -->
      </a-virtual-table>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import moment from 'moment'
  import { formatBytes } from "@/utils/util";
  // import EditFile from './editFile.vue'
  import AVirtualTable from './a-virtual-table.vue'
export default {
  inject: ["refreshRoute"],
  props: {
    fileList: Array,
    fileRequestLoading: Boolean,
    hasSearch: Boolean,
    fileSystemId: String
  },
  computed: {
    rectangleStyle() {
      const width = Math.abs(this.endX - this.startX);
      const height = Math.abs(this.endY - this.startY);
      const left = this.endX > this.startX ? this.startX : this.endX;
      const top = this.endY > this.startY ? this.startY : this.endY;
      return {
        position: 'absolute',
        backgroundColor: '#3974f4',
        opacity: 0.2,
        border: '1px dashed #3974f4',
        width: `${width}px`,
        height: `${height}px`,
        left: `${left - 354}px`,
        top: `${top - 90}px`,
        zIndex: 100
      };
    },
    tableY () {
      const height = document.body.clientHeight
      return height - 178
    },
    rowSelection() {
        const { selectedRowKeys } = this;
        let _this = this;
        return {
          selectedRowKeys,
          onSelectAll: (selectedRowKeys, selectedRows) => {
            _this.selectedRows = selectedRows;
          },
          onChange: (selectedRowKeys, selectedRows) => {
            _this.selectedRowKeys = selectedRowKeys;
            _this.selectedRows = selectedRows;
          },
          getCheckboxProps: (record) => {
            return {
              props: {
                name: record.fullpath,
              },
            };
          },
        };
      },
  },
  watch: {
    hasSearch: {
      handler(val) {
        if(val) {
          this.columns.splice(3, 0, {
          title: "文件路径",
          key: "fullpath",
          dataIndex: "fullpath",
          customRender: (text, row, index) => {
            return (
              <span class="flex-container ell" title={text ? text : '-'}>
               {text ? text : '-'}
              </span>
            );
          },
        },)
        }
      },
      immediate: true
    },
  },
  data() {
    return {
      selectCurrentFile: null,
      isDragging: false, // 是否正在拖拽
      startX: 0, // 鼠标按下时的坐标
      startY: 0, // 鼠标按下时的坐标
      endX: 0, // 鼠标松开时的坐标
      endY: 0, // 鼠标松开时的坐标
      isOptSelf: false,
      showRectangle: false,
      isFullScreen: false,
      visible:false,
      code: '',
      file: {},
      lang: 'powershell',
      selectedRows: [],
      selectedRowKeys: [],
      dataSource: [],
      loading: false,
      columns: [
        {
          title: "名称",
          key: "name",
          dataIndex: 'name',
          width: 500,
          ellipsis: true,
          customRender: (text, row, index) => {
            return (
              <span class="flex-container" title={text}>
               <span style="display:inline-block; minWidth: 32px">{row.is_dir ? <svg-icon style="font-size: 32px;margin-right: 4px" icon-class="file-item-dir"></svg-icon> :  <svg-icon style="font-size: 32px; margin-right: 4px" icon-class={this.getIcon(row.name)}></svg-icon> }</span>
               <span style="display: inline-block" class={ row.is_dir ? "file-name ell" : "file-name ell"} onClick={() => this.getProxyFile(row)}>{{text}}</span>
              </span>
            );
          },
        },
        {
          title: "类型",
          key: "type",
          dataIndex: "type",
          ellipsis: true,
          width: 200,
          sorter: (a, b) => {
            return b.type.localeCompare(a.type, 'zh-Hans-CN', { numeric: true });
          },
          customRender: (text, row, index) => {
            return (
              <span class="flex-container">
                {this.handlerFileType(row)}
              </span>
            );
          },
        },
        {
          title: "大小",
          key: "size",
          dataIndex: "size",
          width: 240,
          ellipsis: true,
          sorter: (a, b) => {
            return a.size - b.size;
          },
          customRender: (text, row, index) => {
            return <span>{text ? formatBytes(text) : "-"}</span>;
          }
        },
        {
          title: "更新时间",
          key: "mod_time",
          dataIndex: "mod_time",
          width: 180,
          sorter: (a, b) => {
            const format = "HH:mm:ss MM/D/YYYY";
            const start = moment(a.mod_time).format(format);
            const end = moment(b.mod_time).format(format);
            const diff = moment(end).diff(moment(start), "seconds");
            return diff;
          },
          customRender: (text, row, index) => {
            return (
              <span class="flex-container">
               { moment(row.mod_time).format("MM/DD HH:mm")}
              </span>
            );
          },
        },
        {
          title: "权限",
          key: "permission",
          dataIndex: "permission",
          ellipsis: true,
          width: 200,
          customRender: (text, row, index) => {
            return (
              <span class="flex-container">
               { row?.mode?.permission ? row?.mode?.permission : '-'}
              </span>
            );
          },
        }
      ],
    };
  },
  components: {
    // EditFile
    AVirtualTable
  },
  mounted() {
    // this.dataSource = this.fileList
  },
  methods: {
    // 鼠标每一行的右键事件
    show (event, item, index) {
      let items = [
        {
          icon: 'contextmenu-del',
          label: "删除",
          onClick: () => {
            this.$emit('deleteFile', item)
          },
        },
        {
          icon: 'contextmenu-open',
          label: "打开",
          onClick: () => {
            this.$emit('openFile', item)
          },
        },
        {
          icon: 'contextmenu-rename',
          label: "重命名",
          onClick: () => {
          },
        },
      ]
      if (!item.is_dir) {
        items.unshift({
          label: "下载",
          icon: 'contextmenu-down',
          onClick: () => {
            this.$emit('downFile', item)
          },
        },)
      }
      this.$contextmenu({
        items: items,
        event,
        customClass: "custom-contextmenu",
        zIndex: 3000,
        minWidth: 80,
      });
      return false;
    },
    // 处理表格的每一列
    handleClickRow(record, index){
      return {
        on: {
          contextmenu: (e) => {
            this.selectCurrentFile = record
            if (this.selectedRowKeys.length > 0) return false
            e.preventDefault()
            e.stopPropagation()
            this.show(e, record, index)
          }
        }
      }
    },
    startDrag (event) {
      // if (event.button === 2) return false
      // let num = 0
      // this.fileList.forEach((item, index) => {
      //   const refName = `file-${item.fullpath}`;
      //   const element = this.$refs[refName][0];
      //   if (element.contains(event.target)) {
      //     num++
      //   }
      // })
      // if (num > 0) {
      //   this.isOptSelf = true
      //   this.isDragging = false
      // } else {
      //   this.isDragging = true;
      //   this.startX = event.clientX;
      //   this.startY = event.clientY;
      //   this.endX = event.clientX;
      //   this.endY = event.clientY;
      //   this.isOptSelf = false
      // }
      // // 当前鼠标点击的不在子元素上清空选中的元素
      // if (!this.isOptSelf) this.checkFileList = [];
    },
    dragging (event) {
      // if (!this.isDragging) {
      //   return;
      // }
      // this.isOptSelf = false
      // this.endX = event.clientX;
      // this.endY = event.clientY;
      // const x1 = this.startX > this.endX ? this.endX : this.startX
      // const x2 = this.startX > this.endX ? this.startX : this.endX
      // const y1 = this.startY > this.endY ? this.endY : this.startY
      // const y2 = this.startY > this.endY ? this.startY : this.endY
      // this.selectedRows = []
      // this.selectedRowKeys = []
      // // 遍历所有元素，判断是否在选择区域内      
      // this.fileList.forEach((item, index) => {
      //   const refName = `file-${item.fullpath}`;
      //   const element = this.$refs[refName][0];
      //   const rect = element.getBoundingClientRect();
      //   const m1 = rect.left;
      //   const n1 = rect.top;
      //   const m2 = rect.right;
      //   const n2 = rect.bottom;
      //   const selected = !(x1 > m2 || x2 < m1 || y1 > n2 || y2 < n1);  
      //   // 如果元素被选中，则将其添加到选中的元素列表中
      //   const currentIndex = this.selectedRows.findIndex(file => file.fullpath === item.fullpath) || -1
      //   if (selected) {
      //     this.selectedRows.push(item);
      //   } else {
      //     this.selectedRows.splice(index, 1);
      //   }
      // });
    },
    endDrag (event) {
      // this.isDragging = false;
    },
    parentShow (e) {
      e.preventDefault();
      let items = [
        {
          label: "终端",
          icon: 'contextmenu-cmd',
          onClick: () => {
          },
        },
        {
          label: "新建",
          icon: 'file-add',
          onClick: () => {
            this.$emit('mkdir')
          },
        },
        {
          label: "上传",
          icon: 'contextmenu-upload',
          onClick: () => {
            this.$emit('handleUploadClick')
          },
        },
      ]
      if (this.selectCurrentFile) {
        items.unshift(
          {
          icon: 'contextmenu-rename',
          label: "重命名",
          onClick: () => {
          },
        }
        )
        items.unshift({
          icon: 'contextmenu-open',
          label: "打开",
          onClick: () => {
            this.$emit('openFile', this.selectCurrentFile)
          },
        })
      }
      if (this.selectedRows.length) {
        let fileNum = 0 
        this.selectedRows.map(item => {
          if (!item.is_dir) fileNum++
        })
        items.push(
          {
            label: "删除",
            icon: 'contextmenu-del',
            onClick: () => {
              this.$emit('deleteFile')
            },
          },
        )
        // 只有选中的有文件才给下载
        if (fileNum > 0) {
          items.splice(2,0, {
            label: "下载",
            icon: 'contextmenu-down',
            onClick: () => {
              this.$emit('downFile')
            },
          })
        }
      }
      items.push({
        label: "刷新",
        icon: 'contextmenu-refresh',
        onClick: () => {
          this.$emit('refreshFile', e)
        },
      })
      this.selectCurrentFile = null
      this.$contextmenu({
        items: items,
        event: e,
        customClass: "custom-contextmenu",
        zIndex: 3000,
        minWidth: 80,
      });
      return false;
    },
    handlerFileType (row) {
      let str = '-'
      if (row.mode && row.mode?.mode_desc  && row.mode?.mode_desc.charAt(0) === '-') {
        const arr = row.name.split(".");
        let key = "";
        if (arr.length > 0) key = arr[arr.length - 1];
        str =  global.utils.mapFileManageIcon(key) ? key : row.name.includes('.') ? key :  '文件';
      } else {
        if (row.type) {
          str = row.type === '目录' ? '文件夹' : row.type
        }
      }
      return str      
    },
    handleCancel () {
      this.visible = false
    },
    changeFull (val) {
      this.isFullScreen = val === 'big'
    },
    getIcon(name) {
      const arr = name.split(".");
      let key = "";
      if (arr.length > 0) key = arr[arr.length - 1];
      return global.utils.mapFileManageIcon(key) ? global.utils.mapFileManageIcon(key) : 'file-question2';
    },
    getProxyFile (val) {
      if (this.fileRequestLoading) return false;
      this.selectedRowKeys = [];
      this.selectedRows = [];
      let type = 'file'
      if (this.hasSearch) type = 'search' 
      if (val.is_dir) return this.$emit("onGetProxyFile", val, type);
      if (global.utils.isPreviewFile(val)) return this.$emit('openFile', val)
      this.$emit('getEdit', val)
    },
  },
};
</script>
  <style scoped lang='less'>
.file-list-page {
  ::-webkit-scrollbar {
    /* 控制竖向滚动条的宽度 */
    width: 8px;
    /* 控制横向滚动条的高度 */
    height: 8px;
    background-color: #f7f8fa!important;
  }
  max-height: calc(100vh - 144px);
  height: calc(100vh - 144px);
  overflow-y: auto;
  padding-right: 8px;
  .table-info {
    /deep/ .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
      background: #f7f8fa;
    }
    /deep/ .ant-table-row-selected  {
      background: rgba(232, 240, 253, 0.9)!important;
    }
    /deep/ .ant-table-tbody > tr {
      background: #f7f8fa;
      td {
        padding: 0px 16px;
      }
    } 
    
    /deep/ .ant-table-thead > tr > th {
      background: #f7f8fa;
      // border-right: none;
    }
    /deep/ .ant-table table {
      border: none!important;
    }
    .file-name {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  // /deep/ .ant-table-fixed{
  //   min-width: 400px;
  // }
}
@media (max-width: 1700px) {
  .file-list-page { 
    max-height: calc(100vh - 144px);
    height: calc(100vh - 144px);
   }
}
</style>