<!--
 * @Description: 
 * @Date: 2023-12-10 18:53:20
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-01-23 18:40:13
-->
<template>
  <div class='right-container'>
    <rightfile v-show="activeKey === 'file'"  :ref="'rightContentRef'" :file="file" :treeVisible="treeVisible" :fileSystemId="fileSystemId" />
    <Terminal  v-show="activeKey === 'terminal'" :activeKey="activeKey" :ref="'rightContentRef'" :file="file" :fileSystemId="fileSystemId" :treeVisible="treeVisible" />
    <Blank  v-show="!['file', 'terminal'].includes(activeKey)"  :ref="'rightContentRef'" :file="file" :treeVisible="treeVisible" />
    <BottomTabs  class="bottom-tabs" :bottomTabsArr="bottomTabsArr" :activeKey="activeKey" @getActiveKey="getActiveKey" />
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import rightfile from './file/rightFile.vue'
import Terminal from './terminal'
import BottomTabs from '@/components/commonBottomTabs'
import Blank from './blank'

import { mapActions, mapState } from "vuex";
import { debounce } from 'lodash';

export default {
  props: {
    file: {
      type: Object,
      default: () => {
        return {};
      },
    },
    treeVisible: {
      type: Boolean,
      default: true
    }
  },
  // import引入的组件需要注入到对象中才能使用
  components: {
    rightfile,
    BottomTabs,
    Terminal,
    Blank
  },
  data () {
    // 这里存放数据
    return {
      activeKey: 'file',
      fileSystemId: '',
      bottomTabsArr: [{
        name: '文件',
        key: 'file',
        svgIcon: 'bottom-tab-file'
      },{
        name: '终端',
        key: 'terminal',
        svgIcon: 'bottom-tab-terminal'
      },{
        name: '监控',
        key: 'monitor',
        svgIcon: 'bottom-tab-monitor'
      },{
        name: '桌面',
        key: 'desktop',
        svgIcon: 'bottom-tab-desktop'
      },{
        name: '共享',
        key: 'share',
        svgIcon: 'bottom-tab-share'
      },{
        name: '收藏',
        key: 'collect',
        svgIcon: 'bottom-tab-collect'
      },{
        name: '系统',
        key: 'system',
        svgIcon: 'bottom-tab-system'
      },{
        name: '同步',
        key: 'sync',
        svgIcon: 'bottom-tab-sync'
      },{
        name: '检索',
        key: 'retrieval',
        svgIcon: 'bottom-tab-retrieval'
      },{
        name: '代理',
        key: 'proxy',
        svgIcon: 'bottom-tab-proxy'
      }]
    }
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapState({
      fileManage: (state) => state.fileManage
    }),
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    const self = this
    self.fileSystemId = this.fileManage.showItem.id
    this.$EventBus.$on("setActiveKey", () => {
      this.handleSearchDebounced()
    });
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    handleSearchDebounced: debounce(function() {
      // 处理搜索逻辑
    //  this.activeKey = 'file'
    }, 1000),
    getActiveKey (key) {
      this.activeKey = key
    }
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.right-container {
  position: relative;
  .bottom-tabs {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
  }
}
</style>
