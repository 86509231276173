<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-02-08 18:25:32
 * @LastEditTime: 2024-01-02 16:53:29
 * @FilePath: \awx-ui\src\pages\operationConsole\components\bottomOpe\upload.vue
-->
<template>
  <span>
  <a-upload
    name="file"
    ref="upload"
    :multiple="true"
    :showUploadList="false"
    :remove="handleRemove"
    :beforeUpload="beforeUpload"
  >
    <div class="upload-item">
      <svg-icon
        style="margin-right: 4px"
        icon-class="file-upload"
      />上传
    </div>
  </a-upload>
    <!-- 隐藏的 input 元素 -->
  <input ref="fileInput" type="file" :accept="fileType" multiple style="display: none" @change="handleChange" />
</span>
</template>
      
  <script>
import moment from "moment";
export default {
  props: {
    file: Object,
    treeFileId: String,
    treeFilePath: String
  },
  data () {
    return {
      fileList: [],
      headers: {
        authorization: "authorization-text",
        Authorization: sessionStorage.getItem("token"),
      },
      fileType:
        ".xls, .xlsx, .csv, .zip, .txt, .doc, .docx, .dotx, .dot, .pot, .ppt, .pptx, .json, .js, .html, .css, .less, .scss, .log .jar, .lock, .log, .vue, .java, .jpg, .png, .jpeg, .svg, .gif, .pdf, .mp4, .mp3",
    };
  },
  watch: {
    fileList: {
      handler (val) {
        this.$emit("getFileList", val);
      },
      deep: true,
    },
  },
  methods: {
    clearFileList () {
      this.fileList = []
    },
    handleRemove (file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload (file) {
      if (file.size > 10 * 1024 * 1000) {
        this.$message.error("文件上传最大仅支持10M");
        return;
      }
      file.status = "notUpload";
      file.loading = false;
      file.progress = 0
      // this.fileList.push(file);
      this.hostsImport(file)
      return false;
    },
    handleChange (file) {
      const fileList = file.target.files
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i]; 
        if (file.size > 10 * 1024 * 1000) {
          this.$message.error("文件上传最大仅支持10M");
          return;
        }
        file.status = "notUpload";
        file.loading = false;
        file.progress = 0
        this.hostsImport(file)
      }
    },
    hostsImport(file) {
      const self = this
      const params = {
        filesystem: this.treeFileId
      }
      const formData = new FormData();
      formData.append("file", file);
      this.$axiosPutFormData(
        global.API.getProxyFile + this.treeFilePath + '?filesystem=' + params.filesystem,
        formData
      )
        .then((res) => {
          if ([200, 201, 202, 204].includes(res.status)) {
            setTimeout(() => {
              this.$emit('refreshRoute')
            }, 500)
          } else {
            self.$message.error(`${file.name}上传失败`)
          }
        })
        .catch((res) => {
          console.log(res, 'sadsad')
          self.$message.error(`${file.name}上传失败`)
        });
    },
  },
  created () { },
  mounted () {
    this.$EventBus.$on("removeFile", (file) => {
      this.handleRemove(file);
    });
  },
  beforeDestroy () {
    this.$EventBus.$off("removeFile");
  }
};
</script>
      
  <style lang="less" scoped>
.upload-item {
  cursor: pointer;
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  border: 1px solid transparent;
  border-color: #b1b8c2;
  &:hover, &:focus {
    color: #639aff;
    border-color: #639aff;
  }
  svg {
    position: relative;
    top: -1px;
  }
  span {
    font-size: 14px;
    color: #445166;
    letter-spacing: 0;
    font-weight: 400;
    margin-left: 12px;
  }
}
</style>