<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2024-01-19 19:56:51
 * @FilePath: \awx-ui\src\pages\fileManage\servicesManage\index.vue
-->
<template>
  <div class="add-service">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      class="pl54t40"
    >
      <a-form-model-item label="文件协议" class="radio-warp" prop="kind">
        <a-radio-group v-model="form.kind">
          <a-radio-button value="sftp">
            Sftp
          </a-radio-button>
          <a-radio-button value="ftp">
            FTP
          </a-radio-button>
          <a-radio-button value="http">
            Http
          </a-radio-button>
          <a-radio-button value="webdav">
            WebDav
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="flew-warp">
        <a-form-model-item style="width: 73%;" :wrapperCol="{span: 18}"  :labelCol="{span: 6}" label="主机名称" prop="host">
          <a-input
            v-model="form.host"
            placeholder="请输入主机名称"
          />
        </a-form-model-item>
        <a-form-model-item style="flex: 1;margin-left: 10px;"  :wrapperCol="{span: 24}" :labelCol="{span: 0}" prop="port">
          <a-input
            type="number"
            v-model="form.port"
            placeholder="端口号"
          />
        </a-form-model-item>
      </div>
      <a-form-model-item label="服务名称" prop="name">
        <a-input
          v-model="form.name"
          placeholder="请输入服务名称"
        />
      </a-form-model-item>
      <a-form-model-item label="默认目录" prop="path">
        <a-input
          v-model="form.path"
          placeholder="请输入默认目录"
        />
      </a-form-model-item>
      <a-form-model-item label="加密方式" class="radio-3-warp">
        <a-radio-group v-model="form.encryption">
          <a-radio-button value="no">
            不加密
          </a-radio-button>
          <a-radio-button value="explicit">
            TLS/SSL隐式加密
          </a-radio-button>
          <a-radio-button value="implicit">
            TLS/SSL显式加密
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="flew-warp">
        <a-form-model-item style="width: 73%;" :wrapperCol="{span: 18}"  :labelCol="{span: 6}" label="用户名" prop="user">
          <a-input
            v-model="form.user"
            placeholder="请输入用户名"
          />
        </a-form-model-item>
        <a-form-model-item style="flex: 1;margin-left: 10px;"  :wrapperCol="{span: 24}" :labelCol="{span: 0}" prop="port">
            <a-checkbox v-model="form.is_anonymous">
              匿名登录
            </a-checkbox>
        </a-form-model-item>
      </div>
      <a-form-model-item v-if="!isEdit" label="密码" :prop="form.kind === 'ftp' ? 'nopPass' : 'password'">
        <a-input
          v-model="form.password"
          :type="!isShow ? 'password' : 'text'"
          placeholder="请输入密码"
        />
        <a-icon
          :type="!isShow ? 'eye-invisible' : 'eye'"
          @click.stop="isShow = !isShow"
          style="position: absolute; cursor: pointer; right: 10px; top: 1px"
        />
      </a-form-model-item>
      <a-form-model-item label="密钥">
        <a-textarea   v-model="form.privatekey"
          placeholder="请输入密钥" :rows="3" />
        <uploadTxt @getFileContent="getFileContent" />
       
      </a-form-model-item>
    </a-form-model>
    <div class="ant-modal-confirm-btns-footer">
      <a-button @click.stop="formCancel">取消</a-button>
      <a-button
        style="margin-left: 10px"
        type="primary"
        @click.stop="handleSubmit"
        :loading="loading"
      >保存</a-button>
    </div>
  </div>
</template>
<script>
import UploadTxt from './uploadTxt.vue'
export default {
  components: {
    UploadTxt
  },
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    callBack: Function,
  },
  data () {
    const _this = this
    return {
      editFlag: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      loading: false,
      form: {
        name: '',
        password: '',
        user: '',
        path: '/',
        port: 22,
        host: '',
        kind: 'sftp',
        privatekey: '',
        encryption: 'no',
        is_anonymous: false
      },
      isShow: false,
      rules: {
        kind: [
          { required: true, message: '请选择文件协议', trigger: 'blur' },
        ],
        host: [
          { required: true, message: '请输入主机名称', trigger: 'blur' },
        ],
        port: [
          { required: true, message: '请输入端口号', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请输入服务名称', trigger: 'blur' },
        ],
        path: [
          { required: true, message: '请输入目录地址', trigger: 'blur' },
        ],
        user: [
          { required: false, message: '请输入用户名', trigger: 'blur' },
        ],   
        password: [
          { required: false, message: '请输入密码', trigger: 'blur' },
        ],
        noPass: [
          { required: false, message: '请输入密码', trigger: 'blur' },
        ]
      },

    };
  },
  watch: {
    'form.kind': {
      handler(val) {
        if (val === 'ftp' && !this.form.user) {
          this.form.user = 'anonymous'
        }
        if (val === 'sftp') {
          this.form.port = '22'
        } 
        if (val === 'ftp') {
          this.form.port = '21'
        }
        if (val === 'http') {
          this.form.port = '80'
        }
        if (val === 'webdav') {
          this.form.port = '5005'
        }
      }
    },
    'form.is_anonymous': {
      handler(val) {
        if (val) {
          this.form.user = 'anonymous'
        } else {
          if (this.form.user === 'anonymous') {
            this.form.user = ''
          }
        }
      }
    },
  },
  computed: {
    isEdit () {
      return this.detail?.id || undefined
    }
  },
  methods: {
    getFileContent (body) {
      this.form.privatekey = body
    },
    checkName (rule, value, callback) {
      var reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (reg.test(value)) {
        callback(new Error("名称中不能包含中文"));
      }
      if (/\s/g.test(value)) {
        callback(new Error("名称中不能包含空格"));
      }
      callback();
    },
    checkNumber (rule, value, callback) {
      var reg = /^[1-9]+[0-9]*$/;
      if (!reg.test(value) && value) {
        callback(new Error("请输入数字"));
      }
      callback();
    },
    setPrivatekey () {
      this.$message.warning('正在和鑫哥确定这个功能的实现方式')
    },
    formCancel () {
      this.$destroyAll();
    },
    handleSubmit (e) {
      const _this = this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
        console.log(this.form)
        this.loading = true;
        const ajaxApi = this.detail?.id ? global.API.getProxyFileDir + '/' + this.detail?.id  : global.API.getProxyFileDir
        if (this.detail?.id) {
          this.$axiosPut(ajaxApi, {...this.form, port: Number(this.form.port)})
          .then((res) => {
            this.loading = false;
            if ([200, 201, 204].includes(res.status)) {
              this.$message.success(this.detail?.id ? "更新成功" :"保存成功", 2);
              this.$destroyAll();
              _this.callBack(_this.detail);
            }
          })
          .catch((err) => {
            this.loading = false;
           });
        } else {
          this.$axiosJsonProxyPost(ajaxApi, {...this.form, port: Number(this.form.port)})
          .then((res) => {
            this.loading = false;
            if ([200, 201, 204].includes(res.status)) {
              this.$message.success(this.detail?.id ? "更新成功" :"保存成功", 2);
              this.$destroyAll();
              _this.callBack(_this.detail);
            }
          })
          .catch((err) => {
            this.loading = false;
           });
        }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
  mounted () {
    console.log(this.detail, 'detail')
    if (this.detail?.id) {
      this.form = {
        name: this.detail?.name || '',
        password: this.detail?.password || '',
        user: this.detail?.user || '',
        path: this.detail?.path || '/',
        port: this.detail?.port || 22,
        host: this.detail?.host || '',
        kind: this.detail?.kind || 'sftp',
        privatekey: this.detail?.privatekey || '',
        encryption: this.detail?.encryption || 'no',
        is_anonymous: this.detail.user === "anonymous"
      }
    }
  },
};
</script>
<style lang="less" scoped>
.add-service {
  .flew-warp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 91.7%;
  }
  .pl54t40 {
    padding: 30px 20px 0 54px;
  }
  .ant-modal-confirm-btns-footer {
    margin: 30px 0 0 0;
    border: 1px solid rgba(223, 232, 243, 0.3);
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .radio-warp {
    /deep/ .ant-form-item-children {
      display: inline-block;
      width: 100%;
      .ant-radio-group  {
        width: 100%;
      }
      .ant-radio-button-wrapper  {
        width: 25%;
        text-align: center;
      }
    }
  }
  .radio-3-warp {
    /deep/ .ant-form-item-children {
      display: inline-block;
      width: 100%;
      .ant-radio-group  {
        width: 100%;
      }
      .ant-radio-button-wrapper  {
        width: 40%;
        text-align: center;
      }
      .ant-radio-button-wrapper:first-child {
        width: 20%;
        text-align: center;
      }
    }
  }
  .prikey-icon {
    position: absolute; 
    cursor: pointer;
    right: -36px;
    top: -5px;
    width: 28px;
    height: 28px;
    border: 1px solid rgba(174,180,189,1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      color: @primary-color;
    }
  }
}
</style>
