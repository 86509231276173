<template>
  <a-spin :spinning="fileRequestLoading" class="right-file-loading" :tip="tips">
    <div style="width: 100%; height: 6px; background: #fff"></div>
    <div class="file-list">
      <div class="file-list-header">
        <div :class="[showRoute ? 'file-list-header-left' : 'file-list-header-show-input']" @click="inputPath">
          <div v-if="showRoute" style="display: flex; align-items: center">
            <div class="file-list-header-left-back" v-if="fileRoute.length" @click="backFile($event)">
              <svg-icon icon-class="file-back" />
            </div>
            <div class="header-right">
              <div class="ell" :style="{'display': 'flex', 'align-items': 'center', 'max-width': inputWidth, 'width': inputWidth}">
              <div v-for="(item, index) in fileRoute" :key="index" :class="['route-item', fileRoute.length -1 === index ? 'route-item-selected': '']" @click="getFile(item, index, $event)">
                  <span class="route-item-name"> {{ item.name }}</span> 
                  <span>></span>
                </div>
              </div>
            </div>
            <div class="reload-icon" @click="refreshFile($event)">
              <a-icon type="reload" />
            </div>
          </div>
          <div v-else style="width: 100%;height: 30px;" >
            <a-input v-model="routePath" ref="routeInput" style="width: 100%;height: 30px;" @pressEnter="changePath" @blur="showRoute = true"/>
          </div>
        </div>
        <div class="file-list-header-right">
          <a-input style="width: 180px; height: 30px;" class="height30" v-model="searchValue" @pressEnter="changeSearchValue" placeholder="请输入关键字查询">
            <svg-icon slot="suffix" icon-class="realtime-search" />
          </a-input>
          <a-button v-if="!readFileObj.is_dir" type="text" :disabled="!readFileObj.is_dir" class="mgl8 height30" @click="upload">
            <svg-icon style="margin-right: 4px" icon-class="file-upload" />上传
          </a-button>
          <a-button v-if="!fileRoute.length && readFileObj.is_dir" type="text" :disabled="!readFileObj.is_dir" class="mgl8 height30" @click="upload">
            <svg-icon style="margin-right: 4px" icon-class="file-upload" />上传
          </a-button>
          <!-- treeFileId 是点击左侧的树，树上的文件系统id-->
          <uploadFile ref="uploadFileRef" v-if="fileRoute.length && readFileObj.is_dir" class="mgl8 height30" :file="selectFile" :treeFileId="fileSystemId" :treeFilePath="propPath" @refreshRoute="refreshRoute"/>
          <a-button v-if="readFileObj.is_dir" type="text" class="mgl8 height30" :disabled="!readFileObj.is_dir" @click="mkdir">
            <svg-icon style="margin-right: 4px" icon-class="file-add" />新建
          </a-button>
          <a-button v-if="!readFileObj.is_dir" type="text" class="mgl8 height30" @click="quickOpt">
            <svg-icon style="margin-right: 4px;font-size: 17px;" icon-class="file-quick" />快捷键
          </a-button>
          <a-button type="text" class="mgl8 height30" @click="downFile()">
            <svg-icon style="margin-right: 4px" icon-class="file-down" />下载
          </a-button>
          <a-button type="text" class="mgl8 height30" v-if="readFileObj.is_dir" @click="deleteFile()">
            <svg-icon style="margin-right: 4px" icon-class="filemanage-del" />删除
          </a-button>
          <a-button type="text" class="mgl8 height30" v-if="!readFileObj.is_dir" @click="saveFile">
            <svg-icon style="margin-right: 4px" icon-class="file-save" />保存
          </a-button>
          <div class="file-show-type mgl8">
            <span :class="['show-warp', 'show-warp-card', 'show-warp-seleted']" @click="changeTab()">
              <svg-icon :icon-class="showTab !== 'card' ? 'file-card' : 'file-list'" />
            </span>
          </div>
        </div>
      </div>
      <div class="file-list-body" ref="draggable" v-if="readFileObj.is_dir" :style="{'padding-right': showTab==='card' ? '0px': '0px'}">
        <file-card ref="fileCardRef" v-if="showTab==='card'" :treeVisible="treeVisible" :fileRequestLoading="fileRequestLoading" :fileList="fileList" :fileSystemId="fileSystemId" @onGetProxyFile="onGetProxyFile" @readFile="readFile" @getEdit="getEdit" @deleteFile="deleteFile" @changeFileRequestLoading="changeFileRequestLoading" @mkdir="mkdir" @handleUploadClick="handleUploadClick" @refreshFile="refreshFile" @downFile="downFile" @openFile="openFile" />
        <file-list ref="fileListRef" v-else :fileList="fileList" :fileSystemId="fileSystemId"  :fileRequestLoading="fileRequestLoading" :hasSearch="hasSearch" @readFile="readFile" @onGetProxyFile="onGetProxyFile" @getEdit="getEdit" @deleteFile="deleteFile" @changeFileRequestLoading="changeFileRequestLoading" @mkdir="mkdir" @handleUploadClick="handleUploadClick" @downFile="downFile" @refreshFile="refreshFile" @openFile="openFile" />
      </div>
      <EditFile
          ref="EditFileRef"
          v-else
          :fileCode="readFileObj.code"
          :language="readFileObj.lang"
          :file="readFileObj.file"
          :fileSystemId="fileSystemId"
        />
    </div>
    
  </a-spin>
</template>

<script>
import fileCard from "./fileCard.vue";
import fileList from "./fileList.vue";
import MkdirFile from "./mkdir.vue"
import uploadFile  from "./uploadFile.vue";
import DeleteFile  from "./deleteFile.vue";
import { mapActions, mapState } from "vuex";
import EditFile from './editFile.vue'
import FilePreview from '@/components/filePreview'
import { downloadFileBlob } from '@/utils/util'
import { debounce } from 'lodash';

export default {
  props: {
    fileSystemId: String,
    file: {
      type: Object,
      default: () => {
        return {};
      },
    },
    treeVisible: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      refreshRoute: this.refreshRoute
    }
  },
  data() {
    return {
      tips: '',
      fileRequestLoading: false,
      searchValue: "",
      showTab: "card",
      fileList: [],
      fileRoute: [{
        name:'根目录',
        fullpath: '/'
      }],
      readFileObj:{
        editLoading: false,
        is_dir: true,
        code: '',
        file: {},
        lang: 'powershell'
      },
      selectFile: {},
      showRoute: true,
      routePath: '',
      hasSearch: false,
    };
  },
  watch: {
    file: {
      handler(val) {
        console.log(val, "saass");
      },
      immediate: false,
      deep: true,
    },
  },
  computed: {
    propPath () {
      const str = this.getRequestFullPath()
      return str
    },
    ...mapState({
      fileManage: (state) => state.fileManage
    }),
    inputWidth () {
      let num = this.treeVisible ? 1048 : 1048 - 280
      return `calc(100vw - ${num}px)`
    }
  },
  components: {
    fileCard,
    fileList,
    uploadFile,
    EditFile
  },
  mounted() {
    const self = this    
    this.$EventBus.$on("onOpenFile", (file, parentFile) => {
      if (this.fileSystemId !== this.fileManage.showItem.id) return false
      this.handleSearchDebounced(file, parentFile)
    });
    this.$EventBus.$on("onGetProxyFile", (node, type) => {
      if (self.fileSystemId !== self.fileManage.showItem.id) return false
      self.selectFile = node
      self.getProxyFile(node, type);
    });
    this.$EventBus.$on("onGetEditFile", (data, file) => {
      if (self.fileSystemId !== self.fileManage.showItem.id) return false
      let code = data
      this.lang = 'powershell'
      if (Object.prototype.toString.call(code) === '[object Object]') {
      code = JSON.stringify(code, null, " ");
      }
      this.readFileObj.code = code + ''
      this.readFileObj.file = file
      this.readFileObj.is_dir = false
      if (this.$refs.EditFileRef) this.$refs.EditFileRef.resetCode(code)
      this.getFileRoute(file.fullpath)
    });
    this.$EventBus.$on("setFileRequestLoading", (flag) => {
      if (self.fileSystemId !== this.fileManage.showItem.id) return false
      this.changeFileRequestLoading(flag)
    });
    this.$EventBus.$on("changeLoading", (loading) => {
      if (self.fileSystemId !== this.fileManage.showItem.id) return false
      if (loading === 'loading') {
        self.searchValue = ''
        self.tips = ''
        if (self.showTab === 'card') self.hasSearch = false
      }
      self.loading = loading === 'loading'
      if (this.readFileObj.is_dir) self.fileRequestLoading = loading === 'loading'
    });
    this.$EventBus.$on("onGetFirstTree", (data, node) => {
      if (self.fileSystemId !== this.fileManage.showItem.id) return false
      self.selectFile = node
      let arr = node.parentArr ? _.cloneDeep(node.parentArr) : []
      // 初始化 
      self.fileRoute = arr
      self.fileList = []
      this.readFileObj.is_dir = true
      if (data && data.length > 0) {
        data.map((val) => {
          self.fileList.push({
            title: val.name,
            name: val.name,
            svgIcon: "file-dir",
            key: val.fullpath,
            isLeaf: !val.is_dir,
            id: val.fullpath,
            pid: node.isChild
              ? node.pid
              : node.id,
            isChild: true,
            parentArr: arr,
            scopedSlots: { title: 'host' },
            ...val,
          });
        });
      }
    });
  },
  beforeDestroy() {
    // this.$EventBus.$off("onGetProxyFile");
    // this.$EventBus.$off("changeLoading");
    // this.$EventBus.$off("onGetFirstTree");
    // this.$EventBus.$off("onGetEditFile");
    // this.$EventBus.$off("setFileRequestLoading");
  },
  methods: {
    ...mapActions("fileManage", [
      "setFileRequestLoading"
    ]),
    handleSearchDebounced: debounce(function(file, parentFile) {
      // 处理搜索逻辑
      this.changeFileRequestLoading(false)
      this.getProxyFile({...file, parentFile}, 'openFile');
    }, 1000),
    // 调用编辑器的实例里面的快捷键方法
    quickOpt () {
      const editor = this.$refs.EditFileRef.$refs.editorFile.monacoEditor;
      if (editor) {
        editor.focus();
        editor.trigger('keyboard', 'editor.action.quickCommand');
      }
    },
    // 左边树点击和右边卡片或者list点击文件夹或者文件触发事件
    onGetProxyFile(node, type) {
      this.selectFile = node
      this.getProxyFile(node, type);
    },
    // 
    changeFileRequestLoading (flag) {
      this.fileRequestLoading = flag
    },
    // 预览文件
    openFile (file) {
      const self = this;
      const id = this.fileManage.showItem.id
      if (this.fileRequestLoading) return false
      // 文件夹不行
      if (file.is_dir) return false;
      const arr = file.name.split(".");
      // 没有后缀名不给编辑
      // if (!arr.length) return false
      let key = "";
      if (arr.length > 0) key = arr[arr.length - 1];
      // this.readFile(1, '文件获取中')
      const width = ['mp3'].includes(key) ? 456 :  document.body.clientWidth
      let content = (
        <FilePreview
          filesystemId={id}
          file={file}
          clusterId={self.fileManage.clusterId}
          callBack={
            () => {
              self.readFile(0, '')
            }
          }
        />
      );
      this.$confirm({
        width: width,
        title: '',
        content,
        class: "file-preview-dialog",
        onCancel: () => {
          self.readFile(0, '')
        },
        closable: false,
        icon: () => {
          return <div />;
        },
      })
    },
    // 编辑器保存文件
    saveFile () {
      this.$refs.EditFileRef.submit()
    },
    readFile (loading, tips) {
      this.fileRequestLoading = loading === 1
      this.tips = tips
    },
    getFileRoute(routePath) {
      let arr = [{
        name: '根目录',
        fullpath: '/'
      }]
      let str = ''
      let pathArr = routePath.split('/').filter(item => item)
      if (pathArr.length > 0) {
        pathArr.map(item => {
          arr.push({
            name: item,
            fullpath: str += '/' + item
          })
        })
      }
      this.fileRoute = arr
    },
    inputPath () {
      let str = this.fileRoute[this.fileRoute.length -1].fullpath
      this.routePath = str.includes('//') ? str.replaceAll('//', '/') : str
      this.showRoute = false
      this.$nextTick(() => {
        this.$refs.routeInput.focus()
      })

    },
    changePath () {
      if (!this.routePath) return this.$message.warning('请输入路径')
      this.showRoute = true
      this.getProxyFile({...this.selectFile, fullpath: this.routePath}, 'input')
    },
    // 二进制文件打开 获取二进制内容 打开编辑器
    getEdit(file, refresh){
      if (this.fileRequestLoading) return false
      // 文件夹不行
      if (file.is_dir) return false;
      const arr = file.name.split(".");
      // 没有后缀名不给编辑
      // if (!arr.length) return false
      let key = "";
      if (arr.length > 0) key = arr[arr.length - 1];
      // if(!['txt', 'go', 'js', 'vue', 'yaml', 'sh', 'java', 'log', 'md', 'sql', 'html'].includes(key)) return false
      if ((file.size / 1048576) > 2) {
        this.fileRequestLoading = false
        return this.$message.warning('文件大小超过2M，请使用其它编辑方式')
      } 
      this.readFile(1, '文件读取中')
      const self = this;
      const ajaxApi = global.API.downloadProxyFile
      this.$axiosProxyDown(ajaxApi + file.fullpath, { filesystem: this.fileManage.showItem.id}).then((res) => {
        this.readFile(0, '')
        if ([200, 201, 204].includes(res.status)) { 
         let code = res.data
         this.lang = 'powershell'
         if (Object.prototype.toString.call(code) === '[object Object]') {
          code = JSON.stringify(code, null, " ");
         }
         this.readFileObj.code = code + ''
         this.readFileObj.file = file
         this.readFileObj.is_dir = false

         // 如果是刷新的话需要重置编辑起里面的内容
         if (refresh === 'refresh') this.$refs.EditFileRef.resetCode(code)
         this.getFileRoute(file.fullpath)
        }
      }).catch(res => {
        this.readFile(0, '')
        this.$message.error('当前文件获取内容失败')
      })
    },
    // 操作之后 刷新当前文件
    refreshFile (e) {
      if (e) e.stopPropagation();
      if (!this.readFileObj.is_dir) {
        return this.getEdit(this.readFileObj.file, 'refresh')
      }
      this.getProxyFile(this.selectFile, 'route')
    },
    // 刷新当前路由
    refreshRoute () {
      this.getProxyFile({...this.selectFile,fullpath: this.routePath}, 'route')
    },
    // 新建 
    mkdir () {
      if (!this.fileRoute.length) return this.$message.warning('请先选择一个文件夹')
      const path = this.getRequestFullPath()
      const self = this;
      let width = 600;
      let content = (
        <MkdirFile
          file={self.selectFile}
          treeFileId={self.fileManage.showItem.id}
          treeFilePath={path}
          callBack={() => self.getProxyFile({...self.selectFile}, 'route')}
        />
      );
      this.$confirm({
        width: width,
        title: '新建',
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    // 下载文件
    downFile (file) {
      let arr = []
      let selectFileList = [] 
      if (!file) {
        if (this.readFileObj.is_dir) {
        if (!this.fileRoute.length) return this.$message.warning('请先选择一个文件夹')
          arr = this.showTab === 'card' ? this.$refs.fileCardRef.checkFileList : this.$refs.fileListRef.selectedRows
          selectFileList = arr.filter(item => !item.is_dir)
          if (!selectFileList.length) return this.$message.warning('请至少选择一个文件')
        } else {
          selectFileList = [this.readFileObj.file]
        }
      } else {
        selectFileList = [file]
      }
      selectFileList.map(item => { 
        let apiAjax = localStorage.getItem('FILE_SYSTEM_URL') + '/filesystem/files/root'
        downloadFileBlob(apiAjax + item.fullpath, item.name, {filesystem: this.fileManage.showItem.id})
        return;
      })

    },
    upload () {
      if (!this.fileRoute.length) return this.$message.warning('请先选择一个文件夹')
    },
    // 外部按钮调用上传
    handleUploadClick() {
      // 获取 Upload 组件的引用
      const upload = this.$refs.uploadFileRef.$refs.fileInput;
      // 调用 click 方法触发文件选择对话框
      upload.click();
    },
    changeSearchValue() {
      if (!this.fileRoute.length) return this.$message.warning('请先选择一个文件夹')
      this.showTab = 'list'
      this.hasSearch = true
      this.getProxyFile({...this.selectFile, fullpath: this.routePath}, 'route', 'search')
    },
    // 删除选中的文件
    deleteFile (file) {
      if (!this.fileRoute.length) return this.$message.warning('请先选择一个文件夹')
      let arr = []
      if (!file) {
        arr = this.showTab === 'card' ? this.$refs.fileCardRef.checkFileList : this.$refs.fileListRef.selectedRows
      } else {
        arr = [file]
      }
      if (!arr.length) return this.$message.warning('请至少选择一个文件')
      const self = this;
      let width = 300;
      let content = (
        <DeleteFile
          file={this.selectFile}
          fileList={arr}
          treeFileId={this.fileManage.showItem.id}
          callBack={() => {
            self.getProxyFile(self.selectFile, 'route');
            if (this.showTab === 'card') {
              this.$refs.fileCardRef.checkFileList = []
            } else {
              this.$refs.fileListRef.selectedRows = []
              this.$refs.fileListRef.selectedRowKeys = []
            }
          }}
        />
      );
      this.$confirm({
        width: width,
        title: '删除',
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    // 卡片和列表模式切换
    changeTab(tab) {
      if (!this.readFileObj.is_dir) return false;
      this.showTab = this.showTab === 'list' ? 'card' : 'list';
    },
    getFile (node, index, e) {
      if (e) e.stopPropagation();
      if (index === this.fileRoute.length -1 || this.fileRoute.length === 1) return false
      this.fileRoute = this.fileRoute.splice(0, index + 1)
      this.selectFile = node
      this.getProxyFile(node, 'route')
    },
    // 返回上一级文件
    backFile (e) {
      if (e) e.stopPropagation();
      if (this.fileRoute.length === 1) return false
      this.fileRoute.pop()
      this.selectFile = this.fileRoute[this.fileRoute.length - 1]
      this.getProxyFile(this.fileRoute[this.fileRoute.length - 1], 'route')
    },
    getRequestFullPath () {
      let str = this.fileRoute[this.fileRoute.length -1].fullpath
      if (str.includes('//')) return str.replaceAll('//', '/')
      return str
    },
    // 获取当前目录下文件
    getProxyFile(file, type='file', getTypeFlag='file') {
      let node = file
      if (type === 'openFile') node = file.parentFile
      this.readFileObj.is_dir = true
      if (this.fileRequestLoading) return false
      this.showRoute = true
      this.fileRequestLoading = true
      // 所有不是从搜索框进入的 都把搜索值清掉
      if (getTypeFlag !== 'search') {
        this.searchValue = ''
        this.tips = ''
      } else {
        this.tips = '正在检索中'
      }
      if (this.showTab === 'card') this.hasSearch = false
      if (['route'].includes(type)) node.fullpath = this.getRequestFullPath()
      let apiAjax = global.API.getProxyFile + `${node.fullpath}`;
      const params = {
        filesystem: this.fileManage.showItem.id, // 取当前这个Tab页签的filesystemId
        keyword: this.searchValue
      };
      this.$axiosProxyGet(apiAjax, params).then((res) => {
        const result = res.data;
        this.fileRequestLoading = false
        if ([200, 201, 204].includes(res.status)) {
          let arr = node.parentArr ? _.cloneDeep(node.parentArr) : []
          // 初始化 
          if (['tree', 'openFile'].includes(type)) this.fileRoute = arr
          // 选中文件
          if (type === 'file') this.fileRoute.push({name: node.name, fullpath: node.fullpath, ...node})
          // 根据路径输入
          if (type === 'input') this.getFileRoute(this.routePath)
          if (type === 'search') this.getFileRoute(node.fullpath)
          this.fileList = []
          if (result && result.length > 0) {
            result.map((val) => {
              this.fileList.push({
                title: val.name,
                name: val.name,
                svgIcon: "file-dir",
                key: val.fullpath,
                isLeaf: !val.is_dir,
                id: val.fullpath,
                pid: node.isChild
                  ? node.pid
                  : node.id,
                isChild: true,
                parentArr: arr,
                scopedSlots: { title: 'host' },
                ...val,
              });
            });
          }
          if (type === 'openFile') this.openFile(file)
        } else {
          this.fileRequestLoading = false
        }
      }).catch(() => {
        this.fileRequestLoading = false
      })
    },
  },
};
</script>
<style scoped lang='less'>

.right-file-loading {
  height: 100%;
   /deep/ .ant-spin{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
}
.file-list {
  height: calc(100vh - 90px);
  &-header {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    border-bottom: 1px solid #CFD5DE;
    &-left {
      display: flex;
      align-items: center;
      // padding: 4px 10px;
      flex: 1;
      border: 1px solid#CFD5DE;
      border-radius: 4px;
      margin-right: 10px;
      line-height: 20px;
      cursor: pointer;
      .route-item {
        cursor: pointer;
        &-name {
          padding:0 6px;
          &:hover {
            background: rgb(229, 243, 255)
          }
        }
        &-selected {
          // color: #34343C;
          font-weight: 600;
        }
      }
      &-back {
        line-height: 30px;
        padding-right: 8px;
        padding-left: 10px;
        height: 30px;
        border-radius:4px 0 0 4px ;
        border-right: 1px solid #cfd5de;
        margin-right: 6px;
        cursor: pointer;
        svg {
          font-size: 15px;
        }
        &:hover {
          background: #cfd5de;
        }
      }
      .header-right {
        display: flex;
        padding: 4px 0px 4px 0;
      }
      .reload-icon {
        border-left: 1px solid #cfd5de;
        width: 34px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        &:hover {
          background: #cfd5de;
        }
      }
    }
    &-show-input {
      display: flex;
      align-items: center;
      flex: 1;
      border-radius: 4px;
      margin-right: 10px;
      cursor: pointer;
      .route-item {
        cursor: pointer;
        &-name {
          padding:0 6px;
          &:hover {
            background: rgb(229, 243, 255)
          }
        }
        &-selected {
          // color: #34343C;
          font-weight: 600;
        }
      }
      &-back {
        line-height: 16px;
        padding-right: 8px;
        height: 16px;
        border-right: 1px solid #cfd5de;
        margin-right: 6px;
        svg {
          font-size: 15px;
          cursor: pointer;
        }
      }
    }
    &-right {
      display: flex;
      // width:604px;
      .file-show-type {
        display: flex;
        .show-warp {
          cursor: pointer;
          width: 34px;
          height: 30px;
          border: 1px solid rgba(186, 193, 201, 1);
          border-right: none;
          line-height: 28px;
          text-align: center;
          &:last-child {
            border-right: 1px solid rgba(186, 193, 201, 1);
          }
          &-card {
            // border-radius: 3px 0px 0px 3px;
            border-radius: 3px;
          }
          &-list {
            border-radius: 0 3px 3px 0px;
          }
        }
        .show-warp-seleted {
          border: 1px solid rgba(57, 116, 244, 1) !important;
          svg {
            color: #3974f4;
          }
        }
      }
      .height30 {
        height: 30px;
        background: #fff;
      }
      /deep/ .ant-input {
        height: 30px!important;
      }
    }
  }
  .select-warp {
    width: 100%;
    height: 40px;
    background: #F7F9FC;
  }
}
</style>