<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-02-06 14:55:25
 * @LastEditTime: 2024-05-22 16:48:13
 * @FilePath: \awx-ui\src\pages\fileManage\leftTree.vue
-->
<template>
  <div
    class="left-tree"
    :style="{ height: 'calc(100% - 10px)' }"
  >
    <div class="sider-header-button">
      <!-- <a-input style="width: calc(100% - 67px)" v-model="searchValue" @pressEnter="changeSearchValue" placeholder="输入主机名称或IP检索">
        <svg-icon slot="suffix" icon-class="realtime-search" />
      </a-input> -->
      <a-select
        style="width: calc(100% - 60px); margin-left: 24px;"
        v-model="cluster_id"
        @change="changeClusterId"
      >
        <a-select-option
          v-for="(item, index) in masterOptions"
          :disabled="item.status !== 'online'"
          :key="item.id"
          :value="item.id"
        >
          <span class="flex-center-container">
            <svg-icon
              class="mgr8"
              :icon-class="item.status === 'online' ? 'task-successful' : 'task-init'"
              :style="{color: item.status === 'online' ? '#5CB863' : '#ED4F50'}"
            ></svg-icon>
            <span>{{ item.name }}</span>
          </span>
        </a-select-option>
      </a-select>
      <span
        class="mgl10 curpon"
        @click="createServices({})"
        title="添加服务"
      ><a-icon
          style="font-size: 18px"
          type="plus-circle"
        /></span>
    </div>
    <a-spin
      :spinning="treeSpain"
      class="left-tree-container"
    >
      <div
        :class="'sysDevStyle'"
        style="overflow-y: auto; padding: 0 10px"
      >
        <a-tree
          :treeData="treeData"
          class="a-tree-local-layout"
          :key="onlyTreeKey"
          @expand="onExpand"
          :expandedKeys="expandedKeys"
          :autoExpandParent="autoExpandParent"
          :selectedKeys="selectedKeys"
          :load-data="onLoadData"
          defaultExpandAll
          @select="onSelect"
        >
          <template
            slot="host"
            slot-scope="item"
          >
            <div :class="['local-tree-custom']">
              <template>
                <span style="margin: 0 4px 1px">
                  <svg-icon
                    v-if="!item.isLeaf && item.expanded"
                    style="font-size: 16px"
                    icon-class="tree-dir-open"
                  />
                  <svg-icon
                    v-if="!item.isLeaf && !item.expanded"
                    style="font-size: 16px"
                    icon-class="tree-dir"
                  />
                  <svg-icon
                    v-if="item.isLeaf"
                    style="font-size: 16px"
                    :iconClass="getIcon(item.title)"
                  />
                </span>
              </template>
              <span
                class="local-tree-content"
                :title="item.title"
              >
                <span v-if="item.title.indexOf(searchValue) == -1">{{ item.title }}</span>
                <span
                  v-else
                  :title="item.title"
                >
                  {{
                  item.title.substr(
                  0,
                  item.title.indexOf(searchValue)
                  )
                  }}
                  <span style="color: #f50">{{ searchValue }}</span>
                  {{
                  item.title.substr(
                  item.title.indexOf(searchValue) +
                  searchValue.length
                  )
                  }}
                </span>
              </span>
              <span
                v-if="item.pid === 0"
                class="tree-more hide-point"
                style="width: 10px; text-align: right"
              >
                <div @click.stop>
                  <a-popover
                    placement="rightTop"
                    class="popover-index"
                    overlayClassName="popover-index"
                    :content="() => getMoreMenu(item)"
                  >
                    <a-icon
                      type="more"
                      @click="showPopover(item)"
                    />
                  </a-popover>
                </div>
              </span>
            </div>
          </template>
        </a-tree>
      </div>
    </a-spin>
  </div>
</template>
<script>
import SvgIcon from '@/icons/SvgIcon.vue';
import { mapActions, mapState } from "vuex";
import DeleteFilsystem from './deleteFilesystem.vue'
import AddServces from "./servicesManage/index.vue";
function generateUUID () {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}
export default {
  components: { SvgIcon },
  data () {
    return {
      searchValue: "",
      autoExpandParent: true,
      selectedKeys: [],
      expandedKeys: ["主机分组", "目标分组"],
      treefilterData: [],
      cluster_id: undefined,
      treeData: [],
      onlyTreeKey: 0,
      treeSpain: true,
    };
  },
  mounted () {
    this.initClusterid();
  },
  watch: {
    '$route': {
      handler (val) {
        // console.log(val, 'route')
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      fileManage: (state) => state.fileManage
    }),
    masterOptions () {
      const options = localStorage.getItem('masterOptions')
      const masterOptions = (options && options.length) ? JSON.parse(options) : []
      return masterOptions
    }
  },
  methods: {
    ...mapActions("fileManage", [
      "setFileRequestLoading",
      "setSysType",
      "changeRealEditList",
      "changeShowItem",
    ]),
    // 切换集群
    changeClusterId (val) {
      const onlineCluster = this.masterOptions.filter(item => item.id === val)
      let DefaultMaster = {}
      if (onlineCluster.length > 0) {
        DefaultMaster = onlineCluster[0]
      }
      localStorage.setItem('FILE_SYSTEM_URL', DefaultMaster?.public_addr || DefaultMaster?.private_addr || '')
      window.config.FILE_SYSTEM_URL = DefaultMaster.public_addr || DefaultMaster.private_addr || ''
      this.changeRealEditList([])
      this.changeShowItem(null)
      this.convertTreeData()
    },
    initClusterid () {
      if (!this.masterOptions) this.cluster_id = undefined
      const onLlineCluster = this.masterOptions.filter(item => item.status === 'online')
      if (onLlineCluster.length > 0) {
        const DefaultMaster = onLlineCluster.find(item => (item.is_default))
        this.cluster_id = DefaultMaster?.id || onLlineCluster[0]?.id || undefined
      }
      this.convertTreeData();
    },
    getIcon (name) {
      const arr = name.split(".");
      let key = "";
      if (arr.length > 0) key = arr[arr.length - 1];
      return global.utils.mapFileTreeIcon(key)
        ? global.utils.mapFileTreeIcon(key)
        : "tree-question2";
    },
    createServices () {
      this.$emit('createServices', {})
    },
    onExpand (expandedKeys, { node }) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    changeSearchValue () {
      const value = this.searchValue
      if (value == '') {
        this.disTreeData(this.treeData);
        return
      }
      let searchKeys = [];
      //获取展开的keys
      this.treefilterData.map(item => {
        if (item.title.indexOf(value) > -1) {
          searchKeys.push(item.key)
        }
      })
      //重置树
      this.disTreeData(this.treeData, searchKeys);
    },
    disTreeData (data, _expandedKeys) {
      if (data && data.length > 0) {
        data.forEach(item => {
          if (_expandedKeys && _expandedKeys.indexOf(item.key) < 0) {
            item['style'] = { display: 'none' };
          } else {
            item['style'] = { display: 'block' };
          }
          if (item.children && item.children.length != 0) {
            this.disTreeData(item.children, _expandedKeys)
          }
        });
      }
    },
    // 动态获取文件树
    onLoadData (treeNode) {
      return new Promise((resolve) => {
        if (treeNode.dataRef.children && treeNode.dataRef.children.length) {
          resolve();
          return;
        }
        let apiAjax = treeNode.dataRef.isChild ? global.API.getProxyFile + `${treeNode.dataRef.fullpath}` : global.API.getProxyFile + `${treeNode.dataRef.path}`
        const params = {
          filesystem: treeNode.dataRef.isChild ? treeNode.dataRef.pid : treeNode.dataRef.id
        }
        const pid = treeNode.dataRef.isChild ? treeNode.dataRef.pid : treeNode.dataRef.id
        this.$axiosProxyGet(apiAjax, params).then((res) => {
          const result = res.data
          if ([200, 201, 204].includes(res.status)) {
            if (result && result.length > 0) {
              // const treeNodeData = result.filter(item => item.is_dir)
              const treeNodeData = result
              treeNode.dataRef.children = treeNodeData.map((val) => {
                return {
                  title: val.name,
                  name: val.name,
                  svgIcon: 'tree-dir',
                  key: pid + val.fullpath,
                  isLeaf: !val.is_dir,
                  id: val.fullpath,
                  pid: pid,
                  isChild: true,
                  parentArr: treeNode.dataRef.parentArr.concat([
                    {
                      fullpath: val.fullpath,
                      name: val.name,
                      ...val
                    }
                  ]),
                  scopedSlots: { title: treeNode.dataRef.scopedSlots.title },
                  ...val,
                };
              });
              treeNodeData.map((val) => {
                const childNode = {
                  title: val.name,
                  name: val.name,
                  svgIcon: 'tree-dir',
                  key: pid + val.fullpath,
                  isLeaf: !val.is_dir,
                  id: val.fullpath,
                  pid: pid,
                  isChild: true,
                  parentArr: treeNode.dataRef.parentArr.concat([
                    {
                      fullpath: val.fullpath,
                      name: val.name,
                      ...val
                    }
                  ]),
                  scopedSlots: { title: treeNode.dataRef.scopedSlots.title },
                  ...val,
                };
                this.treefilterData.push(childNode)
              });
              this.treeData = [...this.treeData];
            }
            resolve();
          } else {
            resolve();
          }
        }).catch(res => {
          resolve()
        })
      });
    },
    convertTreeData (type, node) {
      this.treeSpain = true;
      this.treeData = []
      this.selectedKeys = []
      this.expandedKeys = []
      this.onlyTreeKey++
      const params = {
        page: 1,
        page_size: 10000,
        cluster_id: this.cluster_id
      }
      this.$axiosProxyGet(global.API.getProxyFileDir, params).then((res) => {
        this.treeSpain = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results && res.data.results.length > 0) {
            res.data.results.map((item) => {
              const pathArr = item.path.split('/').filter(child => child)
              let parentArr = [{
                ...item,
                name: '根目录',
                fullpath: '/',
                scopedSlots: { title: "host" },
                svgIcon: 'tree-dir'
              }]
              let path = ''
              pathArr.map(child => {
                path = path + '/' + child
                parentArr.push({
                  ...item,
                  fullpath: path,
                  name: child,
                  scopedSlots: { title: "host" },
                  svgIcon: 'tree-dir'
                })
              })
              const childNode = {
                isLeaf: false,
                key: item.id,
                pid: 0,
                svgIcon: 'tree-dir',
                title: item.name,
                fullpath: item.path,
                children: [],
                parentArr: parentArr,
                ...item,
                scopedSlots: { title: "host" },
              }
              this.treefilterData.push(childNode)
              this.treeData.push(childNode);
            });
            if (this.treeData.length > 0 && type !== 'add') {
              let jumpFile = []
              if (this.$route.params?.filesystemId) {
                jumpFile = this.treeData.filter(item => item.id === this.$route.params?.filesystemId)
              }
              if (type === 'edit' && node) {
                jumpFile = this.treeData.filter(item => item.id === node.id)
              }
              let selectFile = jumpFile.length ? jumpFile[0] : this.treeData[0]
              this.pushTable(selectFile)
              this.selectedKeys = [selectFile.key];
              // 
              setTimeout(() => {
                this.$EventBus.$emit('onGetProxyFile', selectFile, 'tree')
              }, 0)
            }
          }
        }
      });
    },
    showPopover (item) {
      this.popoverVisible = true;
    },
    showPopDetail (keys, node) {
      /**
       * 操作集
       */
      this.popoverVisible = false;
      const self = this;
      let width = keys === "edit" ? 646 : 300;
      let title = "编辑服务";
      let content =
        keys === "edit" || keys === "build" ? (
          <AddServces detail={node.dataRef} callBack={(node) => self.convertTreeData('edit', node)} />
        ) : (
          <DeleteFilsystem
            file={node}
            type="group"
            callBack={() => self.convertTreeData()}
          />
        );
      this.$confirm({
        width: width,
        title:
          keys === "edit" || keys === "build"
            ? title
            : () => {
              return (
                <div>
                  <a-icon
                    type="question-circle"
                    style="color:#2F7FD1 !important;margin-right:10px"
                  />
                  提示
                </div>
              );
            },
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    addMenu () {
      const self = this;
      let width = 560;
      let title = "添加主机组";
      let content = <AddGroup callBack={() => self.getHostGroups()} />;
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    destroyAll () {
      this.$destroyAll();
    },
    getMoreMenu (props) {
      const { isLeaf, expanded, type, editStatus, name, dataRef, pNodeId } =
        props;
      let arr = [];
      arr = [
        { name: "编辑", key: "edit" },
        { name: "删除", key: "delete" },
      ];
      return arr.map((item, index) => {
        return (
          <div key={index}>
            <a-button
              class="more-menu-btn"
              style="border-width:0px;min-width:100px;"
              onClick={() => this.showPopDetail(item.key, props)}
            >
              {item.name}
            </a-button>
          </div>
        );
      });
    },
    onSelect (keys, { node }) {
      const _keys = this.selectedKeys
      if (this.fileManage.fileRequestLoading) {
        this.selectedKeys = _keys
        return false
      }
      // if (node.isLeaf) return false
      // 点击打开的父节点的时候将父节点选中收起来
      if (node.expanded && !node.isLeaf) {
        this.expandedKeys = this.expandedKeys.filter(
          (item) => item !== node.eventKey
        );
      }
      if (!keys.length) return false;
      // if (node.dataRef.pid === 0) {
      // 判断当前页面是否已经打开
      const realEditList = _.cloneDeep(this.fileManage.realEditList)
      const openArr = realEditList.filter(item => item.id === keys[0])
      if (openArr.length > 0) return this.changeTable({ ...openArr[0], item: { ...openArr[0] } })

      if (!node.isLeaf) this.$EventBus.$emit('changeLoading', 'loading')
      this.$EventBus.$emit('setFileRequestLoading', true)
      let apiAjax = global.API.getProxyFile + `${node.dataRef.fullpath}`;
      const params = {
        filesystem: node.dataRef?.parentArr[0]?.id,
        keyword: ''
      };
      if (!node.isLeaf) {
        this.$axiosProxyGet(apiAjax, params).then((res) => {
          // todo 这个地方只打开管理节点
          this.$EventBus.$emit('changeLoading', 'ending')
          this.$EventBus.$emit('setFileRequestLoading', false)
          if ([200, 201, 204].includes(res.status)) {
            const result = res.data;
            this.selectedKeys = keys;
            if (node.dataRef.fullpath === '/') {
              this.pushTable(node.dataRef);
            } else {
              const obj = this.treeData.find(item => item.id === node.dataRef.parentArr[0].id)
              this.pushTable(obj);
            }
            setTimeout(() => {
              this.$EventBus.$emit('onGetFirstTree', result, node.dataRef)
            }, 0)
          } else {
            this.selectedKeys = _keys
            this.$EventBus.$emit('changeLoading', 'ending')
            this.$EventBus.$emit('setFileRequestLoading', false)
          }
        }).catch(() => {
          this.selectedKeys = _keys
          this.$EventBus.$emit('changeLoading', 'ending')
          this.$EventBus.$emit('setFileRequestLoading', false)
        })
      } else {
        if (global.utils.isPreviewFile(node.dataRef)) {
          this.selectedKeys = keys;
          const obj = this.treeData.find(item => item.id === node.dataRef.parentArr[0].id)
          this.$EventBus.$emit('setFileRequestLoading', false)
          this.pushTable(obj);
          this.$EventBus.$emit('setActiveKey')
          setTimeout(() => {
            this.$EventBus.$emit('onOpenFile', node.dataRef, node.$parent.dataRef)
          }, 500)
          return false
        }
        this.getEditFile(node.dataRef, keys, _keys)
      }
    },
    getEditFile (file, keys, _keys) {
      const arr = file.name.split(".");
      // 没有后缀名不给编辑
      // if (!arr.length) return false
      let key = "";
      if (arr.length > 0) key = arr[arr.length - 1];
      // if(!['txt', 'go', 'js', 'vue', 'yaml', 'sh', 'java', 'log', 'md', 'sql', 'html'].includes(key)) return false
      if ((file.size / 1048576) > 2) {
        this.$EventBus.$emit('setFileRequestLoading', false)
        return this.$message.warning('文件大小超过2M，请使用其它编辑方式')
      }
      const ajaxApi = global.API.downloadProxyFile
      setTimeout(() => {
        this.$EventBus.$emit('setFileRequestLoading', false)
      }, 10000)
      this.$axiosProxyDown(ajaxApi + file.fullpath, { filesystem: file.parentArr[0].id }).then((res) => {
        this.$EventBus.$emit('setFileRequestLoading', false)
        if ([200, 201, 204, 202].includes(res.status)) {
          this.selectedKeys = keys;
          if (file.fullpath === '/') {
            this.pushTable(file);
          } else {
            const obj = this.treeData.find(item => item.id === file.parentArr[0].id)
            this.pushTable(obj);
          }
          setTimeout(() => {
            this.$EventBus.$emit('onGetEditFile', res.data, file)
          }, 0)
        } else {
          this.selectedKeys = _keys;
        }
      }).catch(res => {
        this.$message.error('当前文件获取内容失败')

      })
    },
    clickTab (key) {
      this.currentTabsKey = key;
    },
    clickPopCommon (item) {
      if (item.key == "search") {
        this.changeSearchValue();
      }
      if (item.key == "sync") {
        this.getHostGroups();
      }
    },
    pushTable (val) {
      let bool = true;
      let selectItemKey =
        val.index || this.fileManage.realEditList.length;
      this.fileManage.realEditList.map((item, index) => {
        if (
          (val.id && val.id == item.id)
        ) {
          bool = false;
          selectItemKey = index;
        }
      });
      if (bool) {
        let json = {
          index: selectItemKey,
          uuid: generateUUID(),
          ...val,
        };
        const openJosn = _.cloneDeep(json);
        const list = _.cloneDeep(this.fileManage.realEditList);
        //记录当前打开页面
        this.fileManage.showItem = openJosn;
        this.changeShowItem(openJosn);
        list.push(openJosn);
        this.changeRealEditList(list);
      } else {
        this.fileManage.showItem = {
          index: selectItemKey,
          ...this.fileManage.realEditList[selectItemKey],
        };
        this.changeShowItem(this.fileManage.showItem);
      }
      // this.$EventBus.$emit('setActiveKey')
    },
    removeTable (index) {
      const realEditList = _.cloneDeep(this.fileManage.realEditList)
      let bool = false;
      // 每一次关闭之后 如果后面有标签打开后面一个 如果已经是最后一个  打开前面一个
      if (realEditList.length > 0) {
        // 这个是直接关闭当前这个tab
        this.fileManage.realEditList.splice(index, 1);
        this.changeRealEditList(this.fileManage.realEditList);
        // 这个是关闭的标签刚好是打开的这个tab
        if (index == this.fileManage.showItem.index) {
          // 如果已经是最后一个  打开前面一个
          if (index === realEditList.length - 1) {
            this.fileManage.showItem = {
              ...realEditList[index - 1],
              index: index - 1,
            };
          } else {
            // 如果不是是最后一个  打开当前的后面的一个
            this.fileManage.showItem = {
              ...this.fileManage.realEditList[index],
              index: index,
            };
          }
        } else if (index < this.fileManage.showItem.index) {
          this.fileManage.showItem.index--;
        }
        bool = true;
      }
      if (this.fileManage.realEditList.length === 0) this.fileManage.showItem = {
        index: 0
      }
      this.selectedKeys = [this.fileManage.showItem.key]
      this.changeShowItem(this.fileManage.showItem);
      return bool;
    },
    // 右鍵菜单操作
    delOption (e) {
      // 因为前面多了一个所有主机这个独立的不可关闭的tab
      // e.index = e.index - 1
      if (e.type === "other") {
        this.fileManage.realEditList = [this.fileManage.realEditList[e.index]];
        this.fileManage.showItem = {
          ...this.fileManage.realEditList[0],
          index: 1,
        };
        this.selectedKeys = [this.fileManage.showItem.key];
        this.changeShowItem(this.fileManage.showItem);
      }
      if (e.type === "left") {
        this.fileManage.realEditList.splice(0, e.index);
        if (e.index + 1 < this.fileManage.showItem.index) {
          this.fileManage.showItem = {
            ...this.fileManage.realEditList[
            this.fileManage.showItem.index - e.index - 1
            ],
            index: this.fileManage.showItem.index - e.index,
          };
        } else {
          this.fileManage.showItem = {
            ...this.fileManage.realEditList[0],
            index: 1,
          };
        }
        this.selectedKeys = [this.fileManage.showItem.key];
        this.changeShowItem(this.fileManage.showItem);
      }
      if (e.type === "right") {
        this.fileManage.realEditList.splice(
          e.index + 1,
          this.fileManage.realEditList.length
        );
        if (e.index + 1 > this.fileManage.showItem.index) {
          // 
        } else {
          this.fileManage.showItem = {
            ...this.fileManage.realEditList[
            this.fileManage.realEditList.length - 1
            ],
            index: this.fileManage.realEditList.length,
          };
          this.selectedKeys = [this.fileManage.showItem.key];
          this.changeShowItem(this.fileManage.showItem);
        }
      }
      this.changeRealEditList(this.fileManage.realEditList);
    },
    delAll () {
      this.changeRealEditList([]);
      this.changeShowItem(null)
      this.selectedKeys = [];
    },
    changeTable (e) {
      if (e.index == this.fileManage.showItem.index) {
        return;
      }
      let item = e.item || {};
      this.fileManage.showItem = {
        ...e.item,
        index: e.index,
      };
      this.changeShowItem(this.fileManage.showItem);
      // 左侧树选中
      this.selectedKeys = [].concat(item.key + "");
      const arr = item.pKeys || [];
      this.expandedKeys = this.expandedKeys.concat(
        arr.map((v) => {
          return Number(v);
        }) || []
      );
    },
    clickDelIcon (e) {
      let self = this;
      // 删除当前编辑页面
      let bool = this.removeTable(e.index);
      // 重新设置默认打开页面 ==
      if (bool) {
        this.$destroyAll();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.curpon {
  cursor: pointer;
}
// 右边的气泡
.popover-index {
  .more-menu-btn:hover {
    background: #f4f5f7;
    color: #0264c8;
  }
  .ant-popover-inner-content {
    padding: 12px 0;
  }
  /deep/ .ant-popover-arrow {
    top: 20px !important;
    left: 20px !important;
  }
  .ant-popover-inner-content .ant-btn:hover,
  .ant-popover-inner-content .ant-btn:focus {
    background-color: #f4f5f7;
  }
  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 4px;
    margin-top: 1px;
  }
  .ant-menu-inline-collapsed {
    width: 50px;
  }
  .ant-menu-item-selected {
    background: #2872e0 !important;
    color: #ffffff !important;
  }
}
.left-tree {
  // 搜索框
  .sider-header-button {
    padding: 20px 8px 5px 0;
    .ant-input-search {
      margin-left: 16px;
      margin-right: 16px;
      .ant-input-affix-wrapper .ant-input-suffix {
        right: 6px;
      }
    }
    .ant-input-affix-wrapper {
      margin-left: 12px;
      margin-right: 4px;
      margin-left: 24px;
    }
    .ant-input {
      border-radius: 2px;
      margin: 0;
      // background-color: #F2F4F7;
      // border-width:0px
      height: 30px;
      line-height: 30px;
      padding-left: 8px;
    }
    .sider-header-btn {
      box-shadow: none;
    }
  }
  .left-tree-container {
    max-height: calc(100% - 56px);
    overflow-y: auto;
    margin-left: 14px;
    margin-right: 12px;
    .sysDevStyle {
      height: calc(100% - 35px) !important;
      overflow-y: scroll;
      .a-tree-local-layout {
        // padding: 0 14px 0 0px;

        .ant-tree li ul li {
          padding: 5px 0 5px 16px;
          border-bottom: 1px solid #f6f7fb;
        }
        .ant-tree li ul:not(.ant-tree-child-tree-open) {
          padding: 5px 0 5px 16px;
        }
        .ant-tree-child-tree-open {
          padding: 0;
        }
        .ant-tree-treenode-selected:not(.ant-tree-treenode-switcher-open) {
          background: #cce1fe;
          border-radius: 4px;
          .local-tree-content {
            // font-size: 14px;
            color: #0264c8 !important;
            letter-spacing: 0;
            font-weight: 400;
          }
          .popover-index {
            color: #0181ff;
          }
        }
        .ant-tree-treenode-switcher-close {
          // background-color: #fff !important;
        }
        .ant-tree-node-content-wrapper {
          width: calc(100% -24px) !important;
          padding: 0 10px 0 0 !important;
          background: rgba(0, 0, 0, 0) !important;
          opacity: 1 !important;
          border-width: 0px;
          line-height: 24px;
        }
        .local-tree-custom {
          display: flex;
          align-items: center;
          .local-tree-content {
            padding: 0 5px;
            color: #000000a6;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .tree-more {
            color: #f2f4f7;
          }
        }
        .pdl15 {
          position: relative;
          left: -15px;
          .local-tree-content {
            width: calc(100%);
          }
          .tree-more {
            position: relative;
            right: -15px;
          }
        }
        .local-tree-custom:hover {
          .tree-more {
            color: #2281e1;
          }
        }
      }
    }
  }
}
</style>