import { render, staticRenderFns } from "./rightFile.vue?vue&type=template&id=5294167e&scoped=true"
import script from "./rightFile.vue?vue&type=script&lang=js"
export * from "./rightFile.vue?vue&type=script&lang=js"
import style0 from "./rightFile.vue?vue&type=style&index=0&id=5294167e&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5294167e",
  null
  
)

export default component.exports