<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 17:37:26
 * @LastEditTime: 2023-03-28 21:54:19
 * @FilePath: \awx-ui\src\pages\hostManage\components\deleteHost.vue
-->
<template>
  <div>
    <a-form
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      :form="form"
      class="p0-32"
    >
      <a-form-item
        :wrapper-col="{ span: 19, offset: 2 }"
        
      >
        <div>
          <span>确认删除选中的文件吗？</span>
        </div>
      </a-form-item>
    </a-form>
    <div class="ant-modal-confirm-btns-new">
      <a-button
        style="margin-right: 10px"
        type="primary"
        @click.stop="handleSubmit"
      >确定</a-button>
      <a-button @click.stop="formCancel">取消</a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fileList: Array,
    file: Object,
    callBack: Function,
    treeFileId: String
  },
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      form: this.$form.createForm(this),
    };
  },

  methods: {
    handleSubmit (e) {
      let self = this;
      const params = {
        filesystem: this.treeFileId
      }
      this.loading = true;
      const { fileList } = this
      let promiseArr = [];
      fileList.forEach((file, index) => {
        let p = null;
        p = new Promise((resolve) => {
          this.$axiosProxyDelete(global.API.getProxyFile + file.fullpath + '?filesystem=' + params.filesystem ,{}).then((res) => {
            resolve();
          })
          .catch((res) => {
            // 
          });
        });
        if (p) promiseArr.push(p);
      });
      Promise.all(promiseArr).then((res) => {
        this.$message.success("操作成功", 2);
        this.$destroyAll();
        self.callBack();
      });
  },
  formCancel () {
    this.$destroyAll();
  },
},
};
</script>
<style lang="less" scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}
</style>
