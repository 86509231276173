<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-02-08 18:25:32
 * @LastEditTime: 2023-04-09 17:34:49
 * @FilePath: \awx-ui\src\pages\operationConsole\components\bottomOpe\upload.vue
-->
<template>
  <a-upload
    name="file"
    :showUploadList="false"
    @change="handleChange"
    :remove="handleRemove"
    :beforeUpload="beforeUpload"
    :accept="fileType"
  >
  <span class="prikey-icon">
    <svg-icon icon-class="file-lianjie" />
  </span>
  </a-upload>
</template>
      
  <script>
import moment from "moment";
export default {
  data () {
    return {
      fileList: [],
      headers: {
        authorization: "authorization-text",
        Authorization: sessionStorage.getItem("token"),
      },
      fileType:
        ".txt",
    };
  },
  watch: {
    fileList: {
      handler (val) {
        // this.$emit("getFileList", val);
      },
      deep: true,
    },
  },
  methods: {
    clearFileList () {
      this.fileList = []
    },
    handleRemove (file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload (file) {
      if (file.size > 10 * 1024 * 1000) {
        this.$message.error("文件上传最大仅支持10M");
        return;
      }
      file.status = "notUpload";
      file.loading = false;
      file.progress = 0
      // this.fileList.push(file);
      this.hostsImport(file)
      return false;
    },
    handleChange (e) {
      // this.hostsImport()
    },
    hostsImport(file) {
      const self = this
      var reader = new FileReader();
      reader.onload = function () {
          if (reader.result) {
              //打印文件内容
              console.log(reader.result);
              self.$emit('getFileContent', reader.result)
          }
      };
      reader.readAsText(file);
    },
  },
  created () { },
  mounted () {
    this.$EventBus.$on("removeFile", (file) => {
      this.handleRemove(file);
    });
  },
  beforeDestroy () {
    this.$EventBus.$off("removeFile");
  }
};
</script>
      
  <style lang="less" scoped>
  .prikey-icon {
    position: absolute; 
    cursor: pointer;
    right: -36px;
    top: -5px;
    width: 28px;
    height: 28px;
    border: 1px solid rgba(174,180,189,1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      color: @primary-color;
    }
  }
</style>